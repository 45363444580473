body {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #464c54;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

a {
  cursor: pointer;
  color: #464c54;
  text-decoration: none;
}

ul, ol {
  padding: 0;
}
ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
}

li {
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
