.TopSection__mobileMenuIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10000;
}

.TopSection__logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  img {
    cursor: pointer;
  }
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
  display: block;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
  display: block;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  display: none;
}
